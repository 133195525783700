// .storybook/YourTheme.js

import { create } from "@storybook/theming";

export default create({
  base: "light",
  brandTitle: "Organic Module Library",
  brandUrl: "https://theorganicagency.com",
  brandImage:
    "https://storage.googleapis.com/organic-website/assets/Organic_RGB.png",
  brandTarget: "_self",
});
